/* eslint-disable */

import axios from 'axios';

export const baseURLs = {
  DEV: 'https://teledent-dentaquest-api-develop.teledentonline.com',
  STAGING: 'https://teledent-dentaquest-api-staging.teledentonline.com',
  PROD: 'https://teledent-dentaquest-api.teledentonline.com'
};


const applicationDomains = {
  DEV: 'https://dentaquest-develop.teledentonline.com',
  STAGING: 'https://dentaquest-staging.teledentonline.com',
  PROD: 'https://dentaquest.teledentonline.com'
};

const iotPrefixes = {
  DEV: 'develop',
  STAGING: 'staging',
  PROD: 'production'
}


export const apiHost = _apiHost_
export const instance = axios.create({
  baseURL: baseURLs[apiHost], // This is retrieved from webpack DEFINE PLUGIN
  headers: {
    'Content-Type': 'application/json',
  },
});
export const iotPrefix = iotPrefixes[apiHost];
export const applicationDomain = applicationDomains[apiHost];

export default {
  get: async (url, params) => instance.get(url, { params }),
  post: async (url, data = {}) => instance.post(url, data),
  patch: async (url, data = {}) => instance.patch(url, data),
  postFormData: async (url, data) => {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };

    return instance.post(url, data, config);
  },
  delete: async (url) => instance.delete(url),
};

/**
 * Upload media to s3 using presigned url
 */
export const uploadFileToS3 = async (signedUrl, data) => {
  return fetch(signedUrl, {
    method: 'PUT',
    body: data,
    headers: { 'Content-Type': data.type }
  })
}

export const baseURL = baseURLs[apiHost];
